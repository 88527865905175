import React from 'react';
import { graphql, Link } from 'gatsby';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { CallToAction } from '@/components/CallToAction';
import { Social } from '@/components/Social';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import Layout, { Main } from '../../components/Layout';
import { PostCard } from '../../components/PostCard';
import { Newsletter } from '../../components/Newsletter';
import { Content } from '../../components/Content';
import { Seo } from '../../components/SEO';
import { StyledTag } from './styles';

interface ITagRouteTemplate {
  articles?: any;
  tag?: string;
}

interface ITagRoute {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    blogPageImage: any;
    contentfulPage: any;
    allContentfulArticle: any;
  };
  location: any;
  pageContext: {
    tag: any;
  };
}

const TagRouteTemplate = ({ articles, tag }: ITagRouteTemplate) => {
  const breakpoints = React.useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isUnderTablet = [`watch`, `phone`, `phablet`].some(
    (item) => item === breakpoint,
  );
  const shouldShowVerticalPostCard = isUnderTablet;

  return (
    <>
      <Content restrictWidth className="content">
        {articles.map(({ node: article }, idx) => {
          const { publishDate, ...rest } = article;
          return idx === 0 ? (
            <PostCard
              key={rest.id}
              hero
              vertical
              date={publishDate}
              heading={
                <>
                  TAG:{` `}
                  <Badge key={tag} randomColor large noHover>
                    {tag}
                  </Badge>
                </>
              }
              {...rest}
            />
          ) : null;
        })}
      </Content>
      <Content className="content">
        {articles.map(({ node: article }, idx) => {
          const { publishDate, ...rest } = article;
          return idx > 0 ? (
            <PostCard
              key={rest.id}
              date={publishDate}
              {...rest}
              vertical={shouldShowVerticalPostCard}
            />
          ) : null;
        })}
      </Content>
      <Content shouldCenter>
        <Link to="/tags/">
          <Button size="large" disabled={false}>
            Browse all tags
          </Button>
        </Link>
      </Content>
      <Newsletter id="newsletter_blog" hor />
    </>
  );
};

const TagRouteDesktopTemplate = ({ articles }: ITagRouteTemplate) => {
  return (
    <div className="body">
      <Content className="main content" noMargin>
        {articles.map(({ node: article }, idx) => {
          const { publishDate, ...rest } = article;
          console.log({ article, idx });
          return idx >= 0 ? (
            <PostCard key={rest.id} date={publishDate} {...rest} />
          ) : null;
        })}
        <Content shouldCenter noMargin>
          <Link to="/tags/">
            <Button size="large" disabled={false}>
              Browse all tags
            </Button>
          </Link>
        </Content>
      </Content>
      <div className="spacer" />
      <div className="aside">
        <Newsletter id="newsletter_article" forceVert />
      </div>
    </div>
  );
};

const TagRoute = ({ data, location, pageContext }: ITagRoute) => {
  const tag = get(pageContext, `tag`);
  const articles = get(data, `allContentfulArticle.edges`);
  const page = get(data, `blogPage`);
  const {
    title,
    heroImage,
    svgTopPath,
    svgBottomPath,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const image = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);

  return (
    <StyledTag>
      <Layout
        location={location}
        coverImage={image}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            {articles.map(({ node: article }, idx) => {
              const { publishDate, ...rest } = article;
              return idx === 0 ? (
                <PostCard
                  key={rest.id}
                  hero
                  vertical
                  date={publishDate}
                  heading={
                    <>
                      TAG:{` `}
                      <Badge key={tag} randomColor large noHover>
                        {tag}
                      </Badge>
                    </>
                  }
                  {...rest}
                />
              ) : null;
            })}
          </Content>
        }
        desktopBody={<TagRouteDesktopTemplate articles={articles} tag={tag} />}
        desktopCta={
          <Content>
            <CallToAction
              hookId={ctaHookId}
              icon={ctaIcon}
              title={ctaTitle}
              body={ctaBody.ctaBody}
              maxWidth="100%"
              className="cta"
            />
            <Social />
          </Content>
        }
      >
        <Seo
          title={title}
          metaTitle={title}
          metaDesc={tag}
          cover={heroImage}
          slug={tag}
          templateKey="tags"
        />
        <Main background="transparent" disableVertPadding={false}>
          <TagRouteTemplate articles={articles} tag={tag} />
        </Main>
      </Layout>
    </StyledTag>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    blogPage: contentfulPage(slug: { eq: "blog" }) {
      title
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 100, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulArticle(
      limit: 1000
      sort: { fields: [updateDate], order: DESC }
      filter: { tags: { in: [$tag] } }
    ) {
      edges {
        node {
          templateKey
          id
          title
          slug
          timeToRead
          updateDate(formatString: "MMMM Do, YYYY")
          tags
          createDate(formatString: "MMMM Do, YYYY")
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            description
            gatsbyImageData(
              width: 350
              height: 196
              quality: 85
              placeholder: BLURRED
            )
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          excerpt
          author {
            name
            slug
            image {
              gatsbyImageData(
                width: 40
                height: 40
                quality: 100
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
