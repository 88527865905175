import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledTag {
  theme: any;
}

export const StyledTag = styled.div<IStyledTag>`
  .body {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .main {
    flex: 0 1 auto;
  }
  && .heading .content {
    padding-top: ${rem(60)};
  }
  .spacer {
    flex: 1 0 24px;
  }
  .aside {
    flex: 1 0 350px;
    max-width: 350px;
  }
  .cta {
    margin-bottom: ${rem(`24px`)};
  }
  .content {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: 1fr;
    gap: ${rem(`12px`)};
    ${({ theme }) => theme.breakpoints.phablet`
      gap: ${rem(`24px`)};
    `}
  }
`;
